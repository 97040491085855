import React, { useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import { pluck, find, propEq } from 'ramda';
import theme from '@greenberry/salal/theme';
import Heading from '@greenberry/salal/components/Heading';
import Hero from '@greenberry/salal/components/Hero/v2';
import Text from '@greenberry/salal/components/Text';
import Button from '@greenberry/salal/components/Button';
import Icon from '@greenberry/salal/components/Icon';
import MultiButton from '@greenberry/salal/components/MultiButton';
import MainWrapper from '@greenberry/salal/components/MainWrapper';
import SideNavigation from '@greenberry/salal/components/SideNavigation';
import SEO from '../SEO';
import Layout from '../Layout';
import trackIndicatorInView from '../../utils/trackIndicatorInView';
import SubNavigation from '@greenberry/salal/components/SubNavigation';
import useHeader from '../../hooks/useHeader';

const NationalLayout = ({
  title,
  body,
  heroImage,
  children,
  location,
  seoMetaTags,
  sideNavOptions = [],
  selectedIndicator,
}) => {
  const heroElement = createRef();
  const subNavElement = createRef();
  const [heroHeight, setHeroHeight] = useState(470);
  const [subNavHeight, setSubNavHeight] = useState(77);
  const { showHeader } = useHeader();

  const options = pluck('name', sideNavOptions);
  const notStart = location.pathname !== '/landelijk-inzicht/';
  const sectorArray = [
    { label: 'Primair onderwijs', value: 'primair-onderwijs' },
    { label: 'Voortgezet onderwijs', value: 'voortgezet-onderwijs' },
  ];

  const currentSector = sectorArray.find(({ value }) =>
    location.pathname.includes(value)
  );

  useEffect(() => {
    setHeroHeight(heroElement.current && heroElement.current.offsetHeight);
    setSubNavHeight(
      subNavElement.current && subNavElement.current.offsetHeight
    );
  }, [heroElement, subNavElement]);

  useEffect(() => {
    trackIndicatorInView(selectedIndicator);
  }, [selectedIndicator]);

  return (
    <Layout>
      <SEO tags={seoMetaTags.tags} />
      <Hero
        ref={heroElement}
        back={
          notStart && (
            <Button
              color="tertiary"
              disabled={false}
              small
              onClick={() => navigate('/landelijk-inzicht/')}
              styling="ghost"
              type="button"
            >
              <Icon size="lg" type="arrowLeft" />
              <span>terug</span>
            </Button>
          )
        }
        content={
          <>
            <Heading
              appearance="inherit"
              component="h1"
              size="xxxl"
              weight="semibold"
            >
              {title}
            </Heading>
            <Text appearance="inherit" html={false} italic={false} size="l">
              {body}
            </Text>
          </>
        }
        image={heroImage}
      />
      {currentSector && (
        <SubNavigation
          ref={subNavElement}
          style={
            global.window &&
            global.window.matchMedia(
              `(max-width: ${theme.get('breakpoints.3')})`
            ).matches
              ? showHeader
                ? { top: '60px' }
                : { top: '0px' }
              : showHeader
              ? { top: '80px' }
              : { top: '0px' }
          }
          items={[
            {
              id: 'link 1',
              active: location.pathname === `/landelijk-inzicht/`,
              render: (
                <Link to={`/landelijk-inzicht/`}>
                  <Icon type="home" />
                  <span>Start</span>
                </Link>
              ),
            },
            {
              id: 'link 2',
              active: location.pathname.includes('leerlingen'),
              render: (
                <Link
                  to={`/landelijk-inzicht/${currentSector.value}/leerlingen`}
                >
                  <span>Leerlingen</span>
                </Link>
              ),
            },
            {
              id: 'link 3',
              active: location.pathname.includes('financien'),
              render: (
                <Link
                  to={`/landelijk-inzicht/${currentSector.value}/financien`}
                >
                  <span>Financiën</span>
                </Link>
              ),
            },
            {
              id: 'link 4',
              active: location.pathname.includes('organisatie'),
              render: (
                <Link
                  to={`/landelijk-inzicht/${currentSector.value}/organisatie`}
                >
                  <span>Organisatie</span>
                </Link>
              ),
            },
            {
              id: 'link 5',
              active: location.pathname.includes('downloads'),
              render: (
                <Link
                  to={`/samenwerkingsverband/${currentSector.value}/downloads`}
                >
                  <span>Downloads</span>
                </Link>
              ),
            },
          ]}
        />
      )}
      <MainWrapper
        asideStyle={
          global.window &&
          global.window.matchMedia(`(min-width: ${theme.get('breakpoints.2')})`)
            .matches &&
          showHeader
            ? { top: '180px' }
            : { top: '100px' }
        }
        sideNode={
          notStart && (
            <>
              <SideNavigation
                active={selectedIndicator}
                items={options}
                onItemClick={option => {
                  const target = find(propEq('name', option), sideNavOptions);
                  if (target.id) {
                    const heroMargin =
                      global.window &&
                      global.window.matchMedia(
                        `(max-width: ${theme.get('breakpoints.3')})`
                      ).matches
                        ? heroHeight + subNavHeight - 30
                        : heroHeight + subNavHeight - 50;
                    global.window.scrollTo({
                      top:
                        document.getElementById(target.id).offsetTop +
                        heroMargin,
                      left: 0,
                      behavior: 'smooth',
                    });
                  }
                }}
              />
              <MultiButton
                active={currentSector}
                color="primary"
                onChange={({ value }) => {
                  const replaceStr = location.pathname.includes(
                    'voortgezet-onderwijs'
                  )
                    ? 'voortgezet-onderwijs'
                    : 'primair-onderwijs';

                  navigate(location.pathname.replace(replaceStr, value));
                }}
                options={sectorArray}
                styling="solid"
              />
            </>
          )
        }
      >
        {children}
      </MainWrapper>
    </Layout>
  );
};

NationalLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  heroImage: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  seoMetaTags: PropTypes.shape({ tags: PropTypes.arrayOf(PropTypes.object) }),
  sideNavOptions: PropTypes.array.isRequired,
  selectedIndicator: PropTypes.string,
};

export default NationalLayout;
